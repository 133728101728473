(<template>
  <div :class="{'in-progress': progressActive}"
       class="forgot-pass-form is-progress-bar">
    <form class="forgot-pass-form__form">
      <p class="forgot-pass-form__text">{{ pageDescription }}</p>
      <div v-if="isPhoneOption"
           class="forgot-pass-form__inputs-wrapper">
        <sk-select :type="'countryCodes'"
                   :description="phoneCodeText"
                   :default-value="phoneCodeText"
                   :preselected-value="phoneCode"
                   :mob-numeric-keyboard="true"
                   class="forgot-pass-form__code-input"
                   @csvaluechanged="setPhoneCode" />
        <sk-input :description="phoneNumberText"
                  :preselected-value="phoneNumber"
                  :margin-bottom="true"
                  :placeholder="phoneNumberText"
                  :validation-name="phoneNumberErrorsName"
                  :autofocus="true"
                  :type="'tel'"
                  :reg-exp="/[^0-9]/g"
                  :mob-numeric-keyboard="true"
                  class="forgot-pass-form__number-input"
                  @fieldvaluechanged="setPhoneNumber" />
      </div>
      <sk-input v-else
                :id="emailId"
                :preselected-value="email"
                :description="emailText"
                :placeholder="emailText"
                :validation-name="emailErrorsName"
                :type="'email'"
                :icon="false"
                class="forgot-pass-form__email"
                @fieldvaluechanged="setResetPasswordEmail" />
      <div class="forgot-pass-form__btn-cont">
        <button :class="{'is-disabled': disableSubmit}"
                :disabled="disableSubmit"
                class="sk-btn sk-btn--white forgot-pass-form__reset-btn"
                @click.prevent.stop="submitForm(false)">{{ $gettext('Reset password') }}</button>
        <button :class="{'is-disabled': disableSubmit}"
                :disabled="disableSubmit"
                class="sk-btn sk-btn--default"
                @click.prevent.stop="submitForm(true)">{{ $gettext('Send magic link') }}</button>
      </div>
    </form>
    <hr class="nav-login__divider"
        :data-content="orLoginText" />
    <button class="nav-btn"
            @click.prevent.stop="navigateToSSOLogin">
      <span class="nav-btn__text">{{ navButtonText }}</span>
    </button>
    <router-link :to="signInPath"
                 class="sk-link sk-link--destroy-action forgot-pass-form__cancel-link">{{ $gettext('Cancel') }}</router-link>
  </div>
</template>)

<script>
  import {mapMutations, mapState} from 'vuex';
  import constants from '@/modules/constants';
  import helpers from '@/helpers';

  export default {
    data() {
      return {
        progressActive: false,
        emailErrors: [],
        emailErrorsName: 'emailErrors',
        emailId: 'emailId',
        phoneCode: '+47',
        phoneNumber: '',
        phoneNumberErrorsName: 'phoneNumberErrors',
        phoneNumberErrors: []
      };
    },
    computed: {
      ...mapState('TTAuthStore', {
        email: (state) => state.resetPasswordEmail
      }),
      isPhoneOption() { return this.$route.query.option === 'phone'; },
      pageDescription() {
        const template = this.$gettext('Please enter %{option} associated with your account so we can either send you a magic link to log in or reset your password by typing a new one.');
        const option = this.isPhoneOption ? this.$gettext('the phone number') : this.$gettext('the email address');
        return this.$gettextInterpolate(template, {option});
      },
      phoneCodeText() { return this.$gettext('Phone code'); },
      phoneNumberText() { return this.$gettext('Phone number'); },
      emailText() { return this.$gettext('Email'); },
      signInPath() { return this.$makeRoute({name: 'SignIn'}); },
      disableSubmit() {
        return this.isPhoneOption
          ? !this.phoneNumber || !this.phoneCode
          : !this.email.trim() || !this.validateEmail(this.email.trim());
      },
      navButtonText() { return this.$gettext('NAV Employee Login'); },
      orLoginText() { return this.$gettext('or login with'); }
    },
    methods: {
      ...mapMutations('TTAuthStore', ['setResetPasswordEmail']),
      setPhoneCode(value) { this.phoneCode = value; },
      setPhoneNumber(value) { this.phoneNumber = value; },
      validateEmail(email) {
        return helpers.validation.validateEmail(email);
      },
      isValidForm() {
        let isValidForm = true;

        this.emailErrors = [];
        this.phoneNumberErrors = [];
        if (this.isPhoneOption) {
          if (!this.phoneNumber || !this.phoneCode) {
            this.phoneNumberErrors.push(this.$gettext('Please enter an phone number'));
            isValidForm = false;
          }
        } else {
          if (!this.email.trim()) {
            this.emailErrors.push(this.$gettext('Please enter a valid email address'));
            isValidForm = false;
          } else if (!this.validateEmail(this.email.trim())) {
            this.emailErrors.push(this.$gettext('Sorry, this email is not valid. Please try again'));
            isValidForm = false;
          }
        }
        this.$store.commit('ErrorsStore/setError', {name: this.phoneNumberErrorsName, errors: this.phoneNumberErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.emailErrorsName, errors: this.emailErrors});

        return isValidForm;
      },
      submitForm(isMagicLink) {
        if (this.isValidForm()) {
          const form = new FormData();
          const username = this.isPhoneOption ? `${this.phoneCode}${this.phoneNumber}` : this.email.toLowerCase().trim();

          const action = isMagicLink ? 'sendOneTimeLogin' : 'sendResetEmail';
          const modalText = isMagicLink
            ? this.$gettext('We have sent you a magic link to log in. Please contact us if you have any further problems logging in.')
            : this.$gettext('We have sent you an email with a link to reset your password. Please contact us if you have any further problems logging in.');
          form.append('username', username);
          this.progressActive = true;
          this.$store.dispatch(action, form).then(() => {
            this.$router.push(this.$makeRoute({name: 'SignIn'}));
            this.$store.commit('InfoModalStore/setInfoModal', {text: modalText});
          }).catch(() => {
            this.progressActive = false;
            if (this.isPhoneOption) {
              this.phoneNumberErrors.push(this.$gettext('Sorry, could you please check the phone number again?'));
              this.$store.commit('ErrorsStore/setError', {name: this.phoneNumberErrors, errors: this.phoneNumberErrors});
            } else {
              this.emailErrors.push(this.$gettext('Sorry, could you please check the email again?'));
              this.$store.commit('ErrorsStore/setError', {name: this.emailErrorsName, errors: this.emailErrors});
            }
          });
        }
      },
      navigateToSSOLogin() {
        window.location.assign(constants.SAML_PATH);
      }
    },
    beforeMount() {
      const emailField = document.getElementById(this.emailId);
      if (emailField && emailField.value) {
        this.setResetPasswordEmail(emailField.value);
      }
    }
  };
</script>

<style scoped>
  .forgot-pass-form {
    display: block;
    width: 100%;
    padding: 30px 77px 55px;
  }

  .forgot-pass-form__text {
    margin-bottom: 15px;
    text-align: center;
  }

  .forgot-pass-form__email {
    margin-bottom: 20px;
  }

  .forgot-pass-form__btn-cont {
    display: flex;
    justify-content: space-between;
  }

  .forgot-pass-form__reset-btn {
    margin-right: 40px;
  }

  .forgot-pass-form__cancel-link {
    display: block;
    width: 50px;
    margin: 30px auto 0;
    font-weight: normal;
    font-size: 16px;
  }

  .forgot-pass-form__inputs-wrapper {
    display: flex;
  }

  .forgot-pass-form__code-input {
    flex-shrink: 0;
    width: 90px;
    margin-right: 10px;
  }

  .forgot-pass-form__number-input {
    display: inline-block;
    flex-grow: 1;
    width: auto;
  }

  @media (max-width: 767px) {
    .forgot-pass-form {
      padding: 30px 20px 55px;
    }
  }
</style>
