(<template>
  <div class="forgot-pass">
    <auth-tabs :links="forgotPassLinks" />
    <div class="forgot-pass__content-wrapper">
      <forgot-pass />
    </div>
  </div>
</template>)

<script>
  import AuthForgotPassForm from '@/components/auth_components/AuthForgotPassForm';
  import AuthFormTabs from '@/components/auth_components/AuthFormTabs';

  export default {
    components: {
      'auth-tabs': AuthFormTabs,
      'forgot-pass': AuthForgotPassForm
    },
    computed: {
      forgotPassLinks() {
        return [{
          name: this.$gettext('RESET PASSWORD WITH EMAIL'),
          path: this.$makeRoute({name: 'RestorePassword'})
        }, {
          name: this.$gettext('RESET PASSWORD WITH PHONE'),
          path: this.$makeRoute({name: 'RestorePassword', query: {option: 'phone'}})
        }];
      }
    }
  };
</script>

<style scoped>
  .forgot-pass {
    display: block;
    width: 100%;
  }

  .forgot-pass__content-wrapper {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
</style>
